<template>
  <form-wizard ref="refFormWizard" color="#7367F0" :title="null" :subtitle="null" finish-button-text="Submit"
    back-button-text="Previous" hide-buttons class="checkout-form-wizard steps-transparent">
    <b-tabs pills>

      <b-tab active @next-step="nextTabReservation">
        <template #title>
          <feather-icon icon="AlertTriangleIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t('PackageDetails') }}</span>
        </template>


        <b-row>
          <b-row>
            <h3 class="darkBlue mt-9 m-1">{{ medicalPackage.Name }}</h3>
            <h3 class="mt-9 m-1" style="color:darkseagreen;">
              {{ $t("Price") }}: {{ medicalPackage.PriceInUSD }} $
            </h3>
          </b-row>

          <b-row col="12" md="4" class="border-right">

            <b-col col="12" md="3" class="border-right">

              <b-img :src="baseURL + medicalPackage.ImagePath" style="width: 100%; height: 80%;" />
            </b-col>

            <b-col col="12" md="3" class="border-right">
              <span>{{ $t("Description") }}</span>
              <p class="mt-0" v-html="medicalPackage.Description"></p>
              <p class="mt-0">{{ $t("DurationByDay") }} : {{ medicalPackage.DurationByDay }}</p>
            </b-col>
            <b-col cols="12" md="3" class="border-right">
              <b-row>
                <b-col>
                  <h5 class="mt-0"
                    v-if="medicalPackage.MedicalPackageIncludedItems != null && medicalPackage.MedicalPackageIncludedItems != undefined && medicalPackage.MedicalPackageIncludedItems.length > 0">
                    {{ $t("Include") }}</h5>
                  <li class="mt-1" v-for="item in medicalPackage.MedicalPackageIncludedItems" :key="item.Name"
                    :title="item.Name">{{ item.Name }}
                  </li>

                  <!-- <app-timeline-item class="mt-2" v-for="item in medicalPackage.MedicalPackageIncludedItems"
                 :key="item.Name" :title="item.Name" /> -->

                </b-col>
                <b-col>
                  <h5
                    v-if="medicalPackage.MedicalPackageExcludedItems != null && medicalPackage.MedicalPackageExcludedItems != undefined && medicalPackage.MedicalPackageExcludedItems.length > 0"
                    class="mt-0">{{ $t("Exclude") }}</h5>
                  <!-- <app-timeline-item class="mt-2" v-for="item in medicalPackage.MedicalPackageExcludedItems"
                 :title="item.Name" :data="item" /> -->
                  <li class="mt-1" v-for="item in medicalPackage.MedicalPackageExcludedItems" :title="item.Name"
                    :data="item">
                    {{ item.Name }}
                  </li>
                </b-col>

              </b-row>
            </b-col>
            <b-col cols="12" md="3" class="mx-0">
              <p class="">{{ $t("PreferredDate") }}</p>
              <div>
                <v-date-picker v-model="Model.range" multiple="true" is-range :locale="$vs.rtl ? 'ar' : 'en'"
                  @change="changeDateFormat" :min-date="addDays(4)" @dayclick="changeDateFormat" />
              </div>
              <b-row>

                <div class="mt-1">

                  <vs-input class="w-full" v-model="Model.NumberOfCompanions" :placeholder="$t('HowManyPassengers')" />
                  <p class="text-align:center" style="color: rgb(255, 130, 0)">
                    *{{ $t("PassengerNote") }}
                  </p>
                </div>
                <div class="mt-0">
                
                  <vs-input class=" w-full" v-model="Model.NumberOfBeneficiary"
                    :placeholder="$t('NumberOfBeneficiary')" />
                </div>
              </b-row>

            </b-col>
          </b-row>
        </b-row>

      </b-tab>

      <!--Reserve Package Tab-->

      <!-- <b-tab id="TabNext">
        <template #title>
          <feather-icon icon="AlertTriangleIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t('ReservePackage') }}</span>
        </template>

        <b-row col="12" md="4">

          <b-col col="12" md="4">
            <b-row col="12" md="6">

              <b-col col="12" md="6">
                <h3 class=" mt-9 m-1">{{ medicalPackage.Name }}</h3>
              </b-col>
              <b-col col="12" md="6">
                <h3 class="mt-9 m-1" style="color:darkseagreen;">
                  {{ $t("Price") }}: {{ medicalPackage.PriceInUSD }} $
                </h3>
              </b-col>

            </b-row>

            <b-col col="12" md="4">

              <b-img :src="baseURL + medicalPackage.ImagePath" />
            </b-col>
          </b-col>

          <b-col col="12" md="3">
            <p class="vx-col lg:w-1/3">{{ $t("PreferredDate") }}</p>
            <div>
              <v-date-picker v-model="Model.range" multiple="true" is-range :locale="$vs.rtl ? 'ar' : 'en'"
                @change="changeDateFormat" :min-date="addDays(4)" @dayclick="changeDateFormat" />
            </div>

          </b-col>

          <b-col col="12" md="4">
            <b-row>

              <div class="mt-5">

                <p class=" lg:w-1/3">
                  {{ $t("HowManyPassengers") }}

                </p>
                <vs-input class=" lg:w-2/3 w-full" v-model="Model.NumberOfCompanions" />

                <p class="text-align:center" style="color: rgb(255, 130, 0)">
                  *{{ $t("PassengerNote") }}
                </p>
              </div>

            </b-row>

            <b-row>

              <div class="mt-2">
                <p class="lg:w-1/3 w-full">
                  {{ $t("NumberOfBeneficiary") }}
                </p>
                <vs-input class="vx-col lg:w-2/3 w-full" v-model="Model.NumberOfBeneficiary" />
              </div>
            </b-row>
            <div class="
                     mt-5
                     flex flex-wrap
                     justify-center
                   " style="text-align: end">
              <vs-button class="w-full sm:w-auto text-center" color="#2753D9" @click="ReseredMedicalPackage" :disabled="
                !(
                  Model.range &&
                  Model.NumberOfBeneficiary &&
                  Model.NumberOfCompanions
                )
              ">{{ $t("Confirm") }}</vs-button>
            </div>

          </b-col>
        </b-row>
      </b-tab> -->


      <b-row col="12" md="2">
        <b-col col="12" md="2">

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1" @click="back()">
            {{ $t('Back') }}
          </b-button>
        </b-col>
        <b-col col="12" md="2">
          <b-button  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1" @click="ReseredMedicalPackage"
            :disabled="!(Model.range && Model.NumberOfBeneficiary && Model.NumberOfCompanions)">
            {{ $t("Confirm") }}
          </b-button>

          <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1"
         @next-step="nextTabReservation">
         {{ $t('GoToReservation') }}
       </b-button> -->
        </b-col>
      </b-row>
    </b-tabs>
  </form-wizard>


</template>
  
<script>
import {
  BTab, BTabs, BAlert, BAvatar, BBadge, BButton
  , BCard, BCol, BDropdown, BDropdownItem
  , BFormInput, BLink, BMedia, BPagination,
  BRow, BTable, BTooltip, BImg
} from 'bootstrap-vue';
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import moduleReservedPackage from "@/store/reservedPackage/moduleReservedPackage.js";
import UserNotLogin from "@/views/UserNotLogin.vue";
import moment from "moment";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import point from "../../components/point.vue";
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import { ref } from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'

export default {
  components: {
    BAlert,
    UserNotLogin,
    point,
    AppTimeline,
    AppTimelineItem,
    BAvatar, BBadge, BButton, BCard, BCol, BDropdown,
    BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip,
    BTab,
    BImg,
    BTabs,
    FormWizard,
    imageLazy,

  },

  data() {


    return {
      showDismissibleAlert: false,
      EnglishLan: false,
      ShowConfirmation: false,
      Model: {},
      medicalPackage: {},
      ShowLoginPopup: false,
      value1: "",
      baseURL: domain,


    };
  },
  props: {
    PackageID: Number
  },
  watch: {
    PackageID() {
      var id = this.PackageID;
      if (id) {
        this.$store
          .dispatch("MedicalPackageList/GetMedicalPackage", id)
          .then((res) => {
            this.medicalPackage = res.data.Data;
          });
      }
    }
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    return {
      refFormWizard,
      formWizardNextStep,

    }
  },
  methods: {
    nextTabReservation() {
      debugger;
      this.$emit('next-step');

      this.refFormWizard.nextTab();

    },
    startSearchPackages() {
      this.selectedPackage = {}
      this.$store.dispatch(
        "MedicalPackageList/SearchMedicalPackage",
        this.search
      );
    },
    back() {
      this.$router.go(-1);
    },
    changeDateFormat() {
      debugger;
      this.Model.DateFromFormated = moment(this.Model.range.start).format("LL");
      this.Model.DateToFormated = moment(this.Model.range.end).format("LL");
    },
    ConfirmPayment() { },
    addDays(days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    back() {
      this.$router.go(-1);
    },
    ReseredMedicalPackage() {
      debugger;
      var model = {};
      model.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      model.MedicalPackageID = this.medicalPackage.ID;
      model.StatusID = 1;
      model.NumberOfCompanions = this.Model.NumberOfCompanions;
      model.NumberOfBeneficiary = this.Model.NumberOfBeneficiary;
      model.PreferrdDateFrom = this.Model.range.start;
      model.PreferrdDateTo = this.Model.range.end;
      model.MarkupFees = this.medicalPackage.MarkupFees;
      model.VAT = this.medicalPackage.VAT;
      model.PriceAfterDiscount = this.medicalPackage.PriceAfterDiscount;
      model.DurationByDay = this.medicalPackage.DurationByDay;
      this.$store
        .dispatch("ReservatedPackage/AddReservedMedicalPackage", model)
        .then((res) => {
          this.ShowConfirmation = false;

          this.$vs.notify({
            title: this.$t("Successfully"),
            text: this.$t("youhavesuccessfullyreservethispackage"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        });
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
        localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleMedicalPackage.isRegistered) {
      this.$store.registerModule("MedicalPackageList", moduleMedicalPackage);
      moduleMedicalPackage.isRegistered = true;
    }
    if (!moduleReservedPackage.isRegistered) {
      this.$store.registerModule("ReservatedPackage", moduleReservedPackage);
      moduleReservedPackage.isRegistered = true;
    }

    debugger;
    //var id = this.PackageID;
    var id = this.$route.params.ID;
    if (id) {
      this.$store
        .dispatch("MedicalPackageList/GetMedicalPackage", id)
        .then((res) => {
          this.medicalPackage = res.data.Data;
        });
    }
  },
  directives: {
    'height-fade': heightFade,
    Ripple,
  },

};
</script>
