/*=========================================================================================
  File Name: moduleMedicalPackageActions.js
  Description: MedicalPackage Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"


export default {
    AddMedicalPackage({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackage/AddMedicalPackage", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    SearchMedicalPackage({ commit }, search) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackage/SearchMedicalPackage", search)
                .then((response) => {
                    debugger
                    commit('SET_MedicalPackage', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    

    UpdateMedicalPackage({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackage/UpdateMedicalPackage", item)
                .then((response) => {
                    commit('UPDATE_MedicalPackage', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetMedicalPackage({ commit }, itemid) {
        debugger
        return new Promise((resolve, reject) => {
            axios.get("api/MedicalPackage/GetMedicalPackage?packageId=" + itemid)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteMedicalPackage({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/MedicalPackage/DeleteMedicalPackage?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}