/*=========================================================================================
  File Name: moduleMedicalWebinarMutations.js
  Description: MedicalWebinar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.medicalPackages.unshift(item)
  },
  SET_MedicalPackage(state, medicalPackages) {
    debugger
    state.medicalPackages = medicalPackages
  },
  UPDATE_MedicalPackages(state, MedicalWebinar) {
    const MedicalWebinarIndex = state.medicalPackages.findIndex((p) => p.ID == MedicalWebinar.ID)
    Object.assign(state.medicalPackages[MedicalWebinarIndex], MedicalWebinar)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.medicalPackages.findIndex((p) => p.ID == itemId)
    state.medicalPackages.splice(ItemIndex, 1)
},
}
